<template>
  <div ref="pageBox">
    <LabelContent title="用户发布统计" ref="LabelContent"> </LabelContent>
    <div class="tableBox">
      <el-table
        stripe
        ref="multipleTable"
        :data="tableData"
        class="center"
        v-loading="loading"
        :height="tableHeight + 'px'"
        :default-sort="{ prop: 'value', order: 'descending' }"
      >
        <el-table-column prop="userName" label="用户名" />
        <el-table-column sortable prop="collectAmount" label="收藏总数" show-overflow-tooltip />
        <el-table-column sortable prop="integralSurplus" label="积分数" show-overflow-tooltip />
        <el-table-column sortable prop="publishAmount" label="发布总数" show-overflow-tooltip />
        <el-table-column
          sortable
          prop="viewsAmount"
          label="发布文章的浏览量"
          show-overflow-tooltip
        />
      </el-table>
    </div>
  </div>
</template>
<script>
export default {
  name: "Factory",
  data () {
    return {
      total: 0,
      batchDeleteArr: [],
      tableHeight: 0, //表格高度
      tableData: [],
      loading: false,
      query: {
        pageNum: 1,
        pageSize: 10,
        timeEnd: "",
        timeStart: "",
        title: "",
      },
    }
  },
  mounted () {
    //设置表格高度
    this.$nextTick(() => {
      let page = this.$refs.pageBox.offsetHeight || 0 //卡片高度
      let SearchBox = this.$refs.SearchBox
        ? this.$refs.SearchBox.$el.offsetHeight
        : 0 //搜索框高度
      let LabelContent = this.$refs.LabelContent
        ? this.$refs.LabelContent.$el.offsetHeight
        : 0 //内容标题高度
      this.tableHeight = page - (SearchBox + LabelContent + 20 + 30) //padding -20,分页 -30
    })
    this.init()
    console.log(this.tableData)
  },
  methods: {
    async init () {
      let that = this
      let result = await that.$http.request({
        url: "/hw/admin/statistics/user_page",
        method: "GET",
        params: that.query,
      })
      if (result.status == 100) {
        let { data } = result
        that.tableData = data.list
        that.total = data.total
      }
      this.loading = false
    },
    batchDelete () {
      let deleteData = this.batchDeleteArr.map((item) => {
        const { server_asset_id } = item
        return { server_asset_id }
      })
      this.deleteApi(deleteData)
    },
    //分页
    paginationChange (val) {
      console.log(val)
    },
    //表格行过滤
    formatterRow (row, column) {
      return "姓名" + row.value
    },
    //多选框选择 返回当前已选行数据(Array)
    handleSelectionChange (val) {
      this.batchDeleteArr = val
      console.log(val)
      if (val != null) {
        dele: false
      }
    },
    //从后台获取数据,重新排序
    sortChange (val) {
      // 详细文档参考： https://element.eleme.cn/2.13/#/zh-CN/component/table
      console.log(val)
    },
    //自定义排序规则 sort-method
    sortByDate (a, b) {
      console.log(a, b)
      if (a < b) return -1
    },
  },
};
</script>

<style lang="scss" scoped>
.batchdel {
  display: none;
}
.el-button--primary {
  width: 4.5vw;
  height: 2vw;
  line-height: 0px;
}
.stywid {
  margin-left: 1vw;
}
</style>
